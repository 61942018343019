import logo from './logo.svg';
import './App.css';
import { Agency } from './pages/Agency';

function App() {
  return (
    <div>
      <Agency/>
    </div>
  );
}

export default App;
