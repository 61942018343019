import {
  GET_DATA_REQUEST,
  GET_DATA_SUCCESS,
  GET_DATA_FAIL,
  GET_AGENCY_LIST_REQUEST,
  GET_AGENCY_LIST_SUCCESS,
  GET_AGENCY_LIST_FAIL
  } from "./../constant"
  
  export const dataReducer = (state = { data: [],agencyList:[] }, { type, payload }) => {
      switch (type) {
  
        case GET_DATA_REQUEST: return {
            ...state,
            loading: true
        }
        case  GET_DATA_SUCCESS: return {
            ...state,
            data: payload,
            loading: false
        }

        case GET_DATA_FAIL: return {
            ...state,
            error: payload,
            loading: false
        }

        case GET_AGENCY_LIST_REQUEST: return {
            ...state,
            loading: true
        }
        case  GET_AGENCY_LIST_SUCCESS: return {
            ...state,
            agencyList: payload
        }

        case GET_AGENCY_LIST_FAIL: return {
            ...state,
            error: payload,
            loading: false
        }

          default: return state
      }
  }