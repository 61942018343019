import {
    createStore,
    combineReducers,
    applyMiddleware
} from "redux"

import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { dataReducer } from "./reducer"





const rootReducer = combineReducers({
   data:dataReducer
})




const store = createStore(
    rootReducer,
    {},
    composeWithDevTools(applyMiddleware(thunk))
)

export default store