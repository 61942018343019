import React, { useEffect, useState } from 'react'
import { Container,Stack, TextField } from "@mui/material";
import SearchBar from '../../component/search';
import TabSwitch from '../../component/tabs';
import { useDispatch,useSelector } from "react-redux";
import { getAgencyListAction, getDataAction } from '../../redux/action';
import ResultTable from '../../component/table/resultTable';
import RevenueTable from '../../component/table/revenueTable';
import moment from "moment";

import Spinner from 'react-bootstrap/Spinner';


export const Agency = () => {
    const [searchVal, setsearchVal] = useState({value:"",date:""})
  
    
    const dispatch=useDispatch()
    useEffect(() => {
      dispatch(getDataAction(searchVal))
      dispatch(getAgencyListAction())
    }, [searchVal])
    const {data:{result,revenue},agencyList, loading}=useSelector(state=>state.data)
  return (
    <Container style={{marginTop:"40px"}}>
        <Stack gap={3} >
            <SearchBar setsearchVal={setsearchVal} agencyList={agencyList?.data?.result} />

            {!loading && (<TabSwitch 
            component1={<ResultTable result={result}/>}
            component2={<RevenueTable revenue={revenue}/>}/>)}
            {loading && <Stack gap={12} style={{alignItems: "center"}}>
              <Spinner animation="border" />
            </Stack>}
        </Stack>
    </Container>
  )
}
