import React from 'react'
import Table from 'react-bootstrap/Table'; 
import moment from "moment";

function RevenueTable({revenue}) {
  return (
    <>
    {
        revenue?.length ==0 
        ?<h3 className='text-muted text-center'>No data to show</h3>
        :<Table striped responsive bordered hover variant="dark">
        <thead>
          <tr>
            <th>Tag id</th>
            <th>Tag Name</th>
            <th>Report Date</th>
            <th>Total Conversion</th>
            <th>Depositing Users</th>
            <th>Deposit Transactions</th>
            <th>Deposit Amount</th>
          </tr>
        </thead>
        <tbody>
          {revenue?.length >0 && revenue?.map((item,index)=><tr key={index}>
              <td>{item.TAG_ID}</td> 
              <td>{item.TAG_NAME}</td> 
              <td>{moment(item.REPORT_DATE).format('DD-MM-YYYY') }</td> 
              <td>{item.TOTAL_CONVERSION}</td> 
              <td>{item.DEPOSITING_USERS}</td> 
              <td>{item.DEPOSIT_TRANSACTIONS}</td> 
              <td>{item.DEPOSIT_AMOUNT}</td> 
          </tr>)}
        </tbody>
      </Table>
    }
    
  </>
  )
}

export default RevenueTable