import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button"
import moment from "moment";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import agencies from "./../../utils/agency";

function SearchBar({setsearchVal,agencyList}) {
    const [value, setvalue] = useState("")
    const [date, setdate] = useState(Date.now)
    const [age, setage] = useState('')

  return (
    <Box className='search_container'>
 
        <FormControl >
  <InputLabel id="agency">Select agency</InputLabel>
  <Select
    labelId="agency"
    id="agency"
    sx={{ width: 220 }}
    value={value}
    label="Select agency"
    onChange={e=>setvalue(e.target.value)}
  >
  <em>None</em>
    {
      agencyList?.map((agency,index)=> <MenuItem value={agency.AGENCY_ID}>{agency.AGENCY_NAME}</MenuItem>)
    }
  
  </Select>
</FormControl>
     
     <TextField
        id="date"
        label="Date"
        type="date"
        onChange={e=>setdate(e.target.value)}
        defaultValue={Date.now()}
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    
          <Button variant='contained' onClick={e=>setsearchVal({value:value,date:moment(date).format('DD-MM-YYYY') })}>search</Button>
  </Box>
  )
}

export default SearchBar