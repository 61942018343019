import React, { useState } from 'react'
import { TabContext, TabPanel } from '@mui/lab'
import TabList from '@mui/lab/TabList';
import { Box, Button, Tab } from '@mui/material'

function TabSwitch({component1,component2}) {
    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <Box sx={{ width: '100%' }} id="tabs_container">
     <TabContext value={value}>
    <Box sx={{borderBottom:"1px",borderColor:"divider"}}>
        <TabList aria-label="Tabs Example" onChange={handleChange}>
            <Tab label={<Button variant='outlined'>Result</Button>} value="1"/>
            <Tab label={<Button variant='outlined'>Revenue</Button>} value="2"/>
        </TabList>
    </Box>
    <TabPanel value="1">
   {component1}
    </TabPanel>
    <TabPanel value='2' >
{component2}    
</TabPanel>
 
</TabContext>
</Box>
  )
}

export default TabSwitch