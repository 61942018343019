import React from 'react'
import Table from 'react-bootstrap/Table'; 
import moment from "moment";

function ResultTable({result}) {
  return (
    <>
    {
        result?.length ==0 
        ?<h3 className='text-muted text-center'>No data to show</h3>
        :<Table striped responsive bordered hover variant="dark">
        <thead>
          <tr>
            <th>Tag id</th>
            <th>Tag Name</th>
            <th>Report Date</th>
            <th>Total Conversion</th>
            <th>Conversion on Report date</th>
          </tr>
        </thead>
        <tbody>
          {result?.length >0 && result?.map((item,index)=><tr key={index}>
              <td>{item.TAG_ID}</td> 
              <td>{item.TAG_NAME}</td> 
              <td>{moment(item.REPORT_DATE).format('DD-MM-YYYY') }</td> 
              <td>{item.TOTAL_CONVERSION}</td> 
              <td>{item.CONVERSION_ON_REPORT_DATE}</td> 
          </tr>)}
        </tbody>
      </Table>
    }
  </>
  )
}

export default ResultTable