import {
    GET_DATA_REQUEST,
    GET_DATA_SUCCESS,
    GET_DATA_FAIL,
    GET_AGENCY_LIST_REQUEST,
    GET_AGENCY_LIST_SUCCESS,
    GET_AGENCY_LIST_FAIL
    } from "./../constant"
import axios from "axios";

export const getDataAction = ({value,date}) => async (dispatch,getState) => {
    try {
        dispatch({ type:GET_DATA_REQUEST })
       const {data}=await axios.get(`https://payments-test.gemexch.bet/api/reports/tag-conversion-datewise?agencyId=${value}&date=${date}`)
        dispatch({type:GET_DATA_SUCCESS, payload:{
            result:data.result,
            revenue:data.revenue
        }})
    } catch (error) {
      
        dispatch({type:GET_DATA_FAIL, payload: error.message})
    
    }
  }

  export const getAgencyListAction = () => async (dispatch,getState) => {
    try {
        
        dispatch({ type:GET_AGENCY_LIST_REQUEST })
       const res=await axios.get(`https://invalid.saikiseva.com/api/reports/agency-list`)
        dispatch({type:GET_AGENCY_LIST_SUCCESS, payload:res})
    } catch (error) {
      
        dispatch({type:GET_AGENCY_LIST_FAIL, payload: error.message})
    
    }
  }
